import { createComponent } from "./createComponent.mjs";
import { mergeVariants } from "./helpers/mergeVariants.mjs";
import { getReactNativeConfig } from "./setupReactNative.mjs";
function styled(ComponentIn, options, staticExtractionOptions) {
  if (process.env.NODE_ENV !== "production" && !ComponentIn) throw new Error("No component given to styled()");
  const parentStaticConfig = ComponentIn.staticConfig,
    isPlainStyledComponent = !!parentStaticConfig && !(parentStaticConfig.isReactNative || parentStaticConfig.isHOC);
  let Component = parentStaticConfig?.isHOC && !parentStaticConfig?.isStyledHOC || isPlainStyledComponent ? ComponentIn : parentStaticConfig?.Component || ComponentIn;
  const reactNativeConfig = parentStaticConfig ? void 0 : getReactNativeConfig(Component),
    isReactNative = !!(reactNativeConfig || staticExtractionOptions?.isReactNative || parentStaticConfig?.isReactNative),
    staticConfigProps = (() => {
      if (options) {
        let {
          variants,
          name,
          defaultVariants,
          acceptsClassName: acceptsClassNameProp,
          context,
          ...defaultProps
        } = options;
        defaultVariants && (defaultProps = {
          ...defaultVariants,
          ...defaultProps
        }), parentStaticConfig && (parentStaticConfig.isHOC && !parentStaticConfig.isStyledHOC || (defaultProps = {
          ...parentStaticConfig.defaultProps,
          ...defaultProps
        }, parentStaticConfig.variants && (variants = mergeVariants(parentStaticConfig.variants, variants)))), parentStaticConfig?.isHOC && name && (defaultProps.componentName = name);
        const isText = !!(staticExtractionOptions?.isText || parentStaticConfig?.isText),
          acceptsClassName = acceptsClassNameProp ?? (isPlainStyledComponent || isReactNative || parentStaticConfig?.isHOC && parentStaticConfig?.acceptsClassName),
          conf = {
            ...parentStaticConfig,
            ...staticExtractionOptions,
            ...(!isPlainStyledComponent && {
              Component
            }),
            // @ts-expect-error
            variants,
            defaultProps,
            defaultVariants,
            componentName: name || parentStaticConfig?.componentName,
            isReactNative,
            isText,
            acceptsClassName,
            context,
            ...reactNativeConfig,
            isStyledHOC: !!parentStaticConfig?.isHOC,
            parentStaticConfig
          };
        return (defaultProps.children || !acceptsClassName || context) && (conf.neverFlatten = !0), conf;
      }
    })(),
    component = createComponent(staticConfigProps || {});
  for (const key in ComponentIn) key in component || (component[key] = ComponentIn[key]);
  return component;
}
export { styled };