import { AnimatePresence, PresenceChild } from "@tamagui/animate-presence";
import { Fragment, jsx } from "react/jsx-runtime";
function Animate({
  children,
  type,
  ...props
}) {
  return type === "presence" ? props.keepChildrenMounted ? /* @__PURE__ */jsx(PresenceChild, {
    initial: props.initial ? void 0 : !1,
    onExitComplete: props.onExitComplete,
    enterVariant: props.enterVariant,
    exitVariant: props.exitVariant,
    enterExitVariant: props.enterExitVariant,
    presenceAffectsLayout: props.presenceAffectsLayout || !0,
    isPresent: props.present,
    children
  }) : /* @__PURE__ */jsx(AnimatePresence, {
    ...props,
    children: props.present ? children : null
  }) : /* @__PURE__ */jsx(Fragment, {
    children
  });
}
export { Animate };