import "@tamagui/polyfill-dev";
import { Adapt, useAdaptParent } from "@tamagui/adapt";
import { Animate } from "@tamagui/animate";
import { ResetPresence } from "@tamagui/animate-presence";
import { hideOthers } from "@tamagui/aria-hidden";
import { useComposedRefs } from "@tamagui/compose-refs";
import { isWeb } from "@tamagui/constants";
import { Stack, Theme, View, createStyledContext, useEvent, useGet, useMedia, useThemeName } from "@tamagui/core";
import { FloatingOverrideContext } from "@tamagui/floating";
import { FocusScope } from "@tamagui/focus-scope";
import { composeEventHandlers, withStaticProperties } from "@tamagui/helpers";
import { Popper, PopperAnchor, PopperArrow, PopperContent, PopperContentFrame, PopperContext, usePopperContext } from "@tamagui/popper";
import { Portal, PortalHost, PortalItem } from "@tamagui/portal";
import { RemoveScroll } from "@tamagui/remove-scroll";
import { Sheet, SheetController } from "@tamagui/sheet";
import { YStack } from "@tamagui/stacks";
import { useControllableState } from "@tamagui/use-controllable-state";
import * as React from "react";
import { Platform, ScrollView } from "react-native-web";
import { useFloatingContext } from "./useFloatingContext.mjs";
import { jsx, jsxs } from "react/jsx-runtime";
const POPOVER_SCOPE = "PopoverScope",
  PopoverContext = createStyledContext({}),
  usePopoverContext = PopoverContext.useStyledContext,
  PopoverAnchor = React.forwardRef(function (props, forwardedRef) {
    const {
        __scopePopover,
        ...rest
      } = props,
      context = usePopoverContext(__scopePopover),
      {
        onCustomAnchorAdd,
        onCustomAnchorRemove
      } = context || {};
    return React.useEffect(() => (onCustomAnchorAdd(), () => onCustomAnchorRemove()), [onCustomAnchorAdd, onCustomAnchorRemove]), /* @__PURE__ */jsx(PopperAnchor, {
      __scopePopper: __scopePopover || POPOVER_SCOPE,
      ...rest,
      ref: forwardedRef
    });
  }),
  PopoverTrigger = React.forwardRef(function (props, forwardedRef) {
    const {
        __scopePopover,
        ...rest
      } = props,
      context = usePopoverContext(__scopePopover),
      anchorTo = context.anchorTo,
      composedTriggerRef = useComposedRefs(forwardedRef, context.triggerRef);
    if (anchorTo) {
      let virtualRef = {
        current: {
          getBoundingClientRect: () => isWeb ? DOMRect.fromRect(anchorTo) : anchorTo,
          ...(!isWeb && {
            measure: c => c(anchorTo?.x, anchorTo?.y, anchorTo?.width, anchorTo?.height),
            measureInWindow: c => c(anchorTo?.x, anchorTo?.y, anchorTo?.width, anchorTo?.height)
          })
        }
      };
      return /* @__PURE__ */jsx(PopperAnchor, {
        virtualRef,
        __scopePopper: __scopePopover || POPOVER_SCOPE
      });
    }
    if (!props.children) return null;
    const trigger = /* @__PURE__ */jsx(View, {
      "aria-haspopup": "dialog",
      "aria-expanded": context.open,
      "data-state": getState(context.open),
      ...rest,
      ref: composedTriggerRef,
      onPress: composeEventHandlers(props.onPress, context.onOpenToggle)
    });
    return context.hasCustomAnchor ? trigger : /* @__PURE__ */jsx(PopperAnchor, {
      __scopePopper: __scopePopover || POPOVER_SCOPE,
      asChild: !0,
      children: trigger
    });
  }),
  PopoverContent = PopperContentFrame.extractable(React.forwardRef(function (props, forwardedRef) {
    const {
        allowPinchZoom,
        trapFocus,
        disableRemoveScroll = !0,
        zIndex,
        __scopePopover,
        ...contentImplProps
      } = props,
      context = usePopoverContext(__scopePopover),
      contentRef = React.useRef(null),
      composedRefs = useComposedRefs(forwardedRef, contentRef),
      isRightClickOutsideRef = React.useRef(!1);
    return React.useEffect(() => {
      if (!context.open) return;
      const content = contentRef.current;
      if (content) return hideOthers(content);
    }, [context.open]), /* @__PURE__ */jsx(PopoverContentPortal, {
      __scopePopover,
      zIndex: props.zIndex,
      children: /* @__PURE__ */jsx(Stack, {
        pointerEvents: context.open ? "auto" : "none",
        children: /* @__PURE__ */jsx(PopoverContentImpl, {
          ...contentImplProps,
          disableRemoveScroll,
          ref: composedRefs,
          __scopePopover,
          trapFocus: trapFocus ?? context.open,
          disableOutsidePointerEvents: !0,
          onCloseAutoFocus: composeEventHandlers(props.onCloseAutoFocus, event => {
            event.preventDefault(), isRightClickOutsideRef.current || context.triggerRef.current?.focus();
          }),
          onPointerDownOutside: composeEventHandlers(props.onPointerDownOutside, event => {
            const originalEvent = event.detail.originalEvent,
              ctrlLeftClick = originalEvent.button === 0 && originalEvent.ctrlKey === !0,
              isRightClick = originalEvent.button === 2 || ctrlLeftClick;
            isRightClickOutsideRef.current = isRightClick;
          }, {
            checkDefaultPrevented: !1
          }),
          onFocusOutside: composeEventHandlers(props.onFocusOutside, event => event.preventDefault(), {
            checkDefaultPrevented: !1
          })
        })
      })
    });
  }));
function PopoverRepropagateContext(props) {
  return /* @__PURE__ */jsx(PopperContext.Provider, {
    scope: props.scope,
    ...props.popperContext,
    children: /* @__PURE__ */jsx(PopoverContext.Provider, {
      ...props.context,
      children: props.children
    })
  });
}
function PopoverContentPortal(props) {
  const {
      __scopePopover
    } = props,
    zIndex = props.zIndex ?? 15e4,
    context = usePopoverContext(__scopePopover),
    popperContext = usePopperContext(__scopePopover || POPOVER_SCOPE),
    themeName = useThemeName();
  let contents = props.children;
  return (Platform.OS === "android" || Platform.OS === "ios") && (contents = /* @__PURE__ */jsx(PopoverRepropagateContext, {
    scope: __scopePopover || POPOVER_SCOPE,
    popperContext,
    context,
    children: props.children
  })), /* @__PURE__ */jsx(Portal, {
    zIndex,
    children: /* @__PURE__ */jsxs(Theme, {
      forceClassName: !0,
      name: themeName,
      children: [!!context.open && !context.breakpointActive && /* @__PURE__ */jsx(YStack, {
        fullscreen: !0,
        onPress: composeEventHandlers(props.onPress, context.onOpenToggle)
      }), contents]
    })
  });
}
const PopoverContentImpl = React.forwardRef(function (props, forwardedRef) {
    const {
        trapFocus,
        __scopePopover,
        onOpenAutoFocus,
        onCloseAutoFocus,
        disableOutsidePointerEvents,
        disableFocusScope,
        onEscapeKeyDown,
        onPointerDownOutside,
        onFocusOutside,
        onInteractOutside,
        children,
        disableRemoveScroll,
        freezeContentsWhenHidden,
        ...contentProps
      } = props,
      context = usePopoverContext(__scopePopover),
      {
        open,
        keepChildrenMounted
      } = context,
      popperContext = usePopperContext(__scopePopover || POPOVER_SCOPE),
      [isFullyHidden, setIsFullyHidden] = React.useState(!context.open),
      contents = React.useMemo(() => isWeb ? /* @__PURE__ */jsx("div", {
        style: {
          display: "contents"
        },
        children
      }) : children, [children]);
    if (open && isFullyHidden && setIsFullyHidden(!1), !keepChildrenMounted && isFullyHidden) return null;
    if (context.breakpointActive) {
      const childrenWithoutScrollView = React.Children.toArray(children).map(child => React.isValidElement(child) && child.type === ScrollView ? child.props.children : child);
      let content = childrenWithoutScrollView;
      return Platform.OS === "android" || Platform.OS === "ios" ? content = /* @__PURE__ */jsx(PopperContext.Provider, {
        scope: __scopePopover || POPOVER_SCOPE,
        ...popperContext,
        children: /* @__PURE__ */jsx(ResetPresence, {
          children: childrenWithoutScrollView
        })
      }) : content = /* @__PURE__ */jsx(ResetPresence, {
        children: content
      }), /* @__PURE__ */jsx(PortalItem, {
        hostName: `${context.id}PopoverContents`,
        children: content
      });
    }
    return /* @__PURE__ */jsx(Animate, {
      type: "presence",
      present: !!open,
      keepChildrenMounted,
      onExitComplete: () => {
        setIsFullyHidden(!0);
      },
      children: /* @__PURE__ */jsx(PopperContent, {
        __scopePopper: __scopePopover || POPOVER_SCOPE,
        "data-state": getState(open),
        id: context.contentId,
        ref: forwardedRef,
        ...contentProps,
        children: /* @__PURE__ */jsx(RemoveScroll, {
          enabled: disableRemoveScroll ? !1 : open,
          allowPinchZoom: !0,
          removeScrollBar: !1,
          style: {
            display: "contents"
          },
          children: /* @__PURE__ */jsx(ResetPresence, {
            children: /* @__PURE__ */jsx(FocusScope, {
              loop: !0,
              enabled: disableFocusScope ? !1 : open,
              trapped: trapFocus,
              onMountAutoFocus: onOpenAutoFocus,
              onUnmountAutoFocus: onCloseAutoFocus,
              children: contents
            })
          })
        })
      }, context.contentId)
    });
  }),
  PopoverClose = React.forwardRef(function (props, forwardedRef) {
    const {
        __scopePopover,
        ...rest
      } = props,
      context = usePopoverContext(__scopePopover);
    return /* @__PURE__ */jsx(YStack, {
      ...rest,
      ref: forwardedRef,
      componentName: "PopoverClose",
      onPress: composeEventHandlers(props.onPress, () => context.onOpenChange(!1, "press"))
    });
  }),
  PopoverArrow = PopperArrow.styleable(function (props, forwardedRef) {
    const {
        __scopePopover,
        ...rest
      } = props,
      context = usePopoverContext(__scopePopover);
    return useSheetBreakpointActive(context.sheetBreakpoint) ? null : /* @__PURE__ */jsx(PopperArrow, {
      __scopePopper: __scopePopover || POPOVER_SCOPE,
      componentName: "PopoverArrow",
      ...rest,
      ref: forwardedRef
    });
  }),
  Popover = withStaticProperties(React.forwardRef((props, forwardedRef) => {
    const {
        children,
        open: openProp,
        defaultOpen,
        onOpenChange,
        __scopePopover,
        keepChildrenMounted,
        hoverable,
        disableFocus,
        ...restProps
      } = props,
      id = React.useId(),
      {
        when,
        AdaptProvider
      } = useAdaptParent({
        Contents: React.useCallback(() => /* @__PURE__ */jsx(PortalHost, {
          name: `${id}PopoverContents`
        }), [])
      }),
      sheetBreakpoint = when,
      triggerRef = React.useRef(null),
      [hasCustomAnchor, setHasCustomAnchor] = React.useState(!1),
      viaRef = React.useRef(),
      [open, setOpen] = useControllableState({
        prop: openProp,
        defaultProp: defaultOpen || !1,
        onChange: val => {
          onOpenChange?.(val, viaRef.current);
        }
      }),
      sheetActive = useSheetBreakpointActive(sheetBreakpoint),
      floatingContext = useFloatingContext({
        open,
        setOpen: (val, via) => {
          viaRef.current = via, setOpen(val);
        },
        disable: sheetActive,
        hoverable,
        disableFocus
      }),
      [anchorTo, setAnchorTo] = React.useState();
    React.useImperativeHandle(forwardedRef, () => ({
      anchorTo: setAnchorTo
    }));
    const popoverContext = {
        id,
        sheetBreakpoint,
        contentId: React.useId(),
        triggerRef,
        open,
        breakpointActive: sheetActive,
        onOpenChange: (val, via) => {
          viaRef.current = via, setOpen(val);
        },
        onOpenToggle: useEvent(() => {
          open && sheetActive || setOpen(!open);
        }),
        hasCustomAnchor,
        anchorTo,
        onCustomAnchorAdd: React.useCallback(() => setHasCustomAnchor(!0), []),
        onCustomAnchorRemove: React.useCallback(() => setHasCustomAnchor(!1), []),
        keepChildrenMounted
      },
      contents = /* @__PURE__ */jsx(Popper, {
        __scopePopper: __scopePopover || POPOVER_SCOPE,
        stayInFrame: !0,
        ...restProps,
        children: /* @__PURE__ */jsx(PopoverContext.Provider, {
          scope: __scopePopover,
          ...popoverContext,
          children: /* @__PURE__ */jsx(PopoverSheetController, {
            onOpenChange: setOpen,
            children
          })
        })
      });
    return /* @__PURE__ */jsx(AdaptProvider, {
      children: isWeb ? /* @__PURE__ */jsx(FloatingOverrideContext.Provider, {
        value: floatingContext,
        children: contents
      }) : contents
    });
  }), {
    Anchor: PopoverAnchor,
    Arrow: PopoverArrow,
    Trigger: PopoverTrigger,
    Content: PopoverContent,
    Close: PopoverClose,
    Adapt,
    ScrollView,
    Sheet: Sheet.Controlled
  });
function getState(open) {
  return open ? "open" : "closed";
}
const PopoverSheetController = ({
    __scopePopover,
    ...props
  }) => {
    const context = usePopoverContext(__scopePopover),
      showSheet = useShowPopoverSheet(context),
      breakpointActive = context.breakpointActive,
      getShowSheet = useGet(showSheet);
    return /* @__PURE__ */jsx(SheetController, {
      onOpenChange: val => {
        getShowSheet() && props.onOpenChange(val);
      },
      open: context.open,
      hidden: breakpointActive === !1,
      children: props.children
    });
  },
  useSheetBreakpointActive = breakpoint => {
    const media = useMedia();
    return typeof breakpoint == "boolean" || !breakpoint ? !!breakpoint : media[breakpoint];
  },
  useShowPopoverSheet = context => {
    const breakpointActive = useSheetBreakpointActive(context.sheetBreakpoint);
    return context.open === !1 ? !1 : breakpointActive;
  };
export { Popover, PopoverAnchor, PopoverArrow, PopoverClose, PopoverContent, PopoverContext, PopoverTrigger, usePopoverContext };