import { simpleHash } from "@tamagui/helpers";
import { createVariable, isVariable } from "./createVariable.mjs";
const cache = /* @__PURE__ */new WeakMap(),
  createVariables = (tokens, parentPath = "", isFont = !1) => {
    if (cache.has(tokens)) return tokens;
    const res = {};
    let i = 0;
    for (let keyIn in tokens) {
      i++;
      const val = tokens[keyIn],
        isPrefixed = keyIn[0] === "$",
        keyWithPrefix = isPrefixed ? keyIn : `$${keyIn}`,
        key = isPrefixed ? keyWithPrefix.slice(1) : keyIn;
      if (isVariable(val)) {
        res[key] = val;
        continue;
      }
      const niceKey = simpleHash(key);
      let name = isFont ? niceKey.slice(0, 2) : i;
      if (name = parentPath ? `${parentPath}-${name}` : niceKey, val && typeof val == "object") {
        res[key] = createVariables(tokens[key], name, !1
        /* note: don't pass isFont down, we want to avoid it past the first level */);

        continue;
      }
      const finalValue = isVariable(val) ? val : createVariable({
        val,
        name,
        key: keyWithPrefix
      });
      res[key] = finalValue;
    }
    return cache.set(res, !0), res;
  };
export { createVariables };