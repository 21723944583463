import { useComposedRefs } from "@tamagui/compose-refs";
import { isWeb } from "@tamagui/constants";
import { useProps } from "@tamagui/core";
import { registerFocusable } from "@tamagui/focusable";
import { composeEventHandlers, withStaticProperties } from "@tamagui/helpers";
import { useLabelContext } from "@tamagui/label";
import { ButtonNestingContext, YStack } from "@tamagui/stacks";
import { useControllableState } from "@tamagui/use-controllable-state";
import { usePrevious } from "@tamagui/use-previous";
import * as React from "react";
import { Switch as NativeSwitch, Platform } from "react-native-web";
import { SwitchFrame as DefaultSwitchFrame, SwitchThumb } from "./Switch.mjs";
import { SwitchContext } from "./SwitchContext.mjs";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
function createSwitch(createProps) {
  const {
    disableActiveTheme,
    Frame = DefaultSwitchFrame,
    Thumb = SwitchThumb
  } = createProps;
  process.env.NODE_ENV === "development" && (Frame !== DefaultSwitchFrame && Frame.staticConfig.context && console.warn("Warning: createSwitch() needs to control context to pass checked state from Frame to Thumb, any custom context passed will be overridden."), Thumb !== SwitchThumb && Thumb.staticConfig.context && console.warn("Warning: createSwitch() needs to control context to pass checked state from Frame to Thumb, any custom context passed will be overridden.")), Frame.staticConfig.context = SwitchContext, Thumb.staticConfig.context = SwitchContext;
  const SwitchThumbComponent = Thumb.styleable(function (props, forwardedRef) {
      const {
          size: sizeProp,
          unstyled: unstyledProp,
          ...thumbProps
        } = props,
        context = React.useContext(SwitchContext),
        {
          disabled,
          checked,
          unstyled: unstyledContext,
          frameWidth,
          size: sizeContext
        } = context,
        [thumbWidth, setThumbWidth] = React.useState(0),
        initialChecked = React.useRef(checked).current,
        distance = frameWidth - thumbWidth,
        x = initialChecked ? checked ? 0 : -distance : checked ? distance : 0;
      return (
        // @ts-ignore
        /* @__PURE__ */
        jsx(Thumb, {
          ...((unstyledProp ?? unstyledContext ?? !1) === !1 && {
            unstyled: process.env.TAMAGUI_HEADLESS === "1",
            size: sizeProp ?? sizeContext ?? "$true",
            ...(!disableActiveTheme && {
              theme: checked ? "active" : null
            })
          }),
          "data-state": getState(checked),
          "data-disabled": disabled ? "" : void 0,
          alignSelf: initialChecked ? "flex-end" : "flex-start",
          checked,
          x,
          ...thumbProps,
          onLayout: composeEventHandlers(props.onLayout, e =>
          // @ts-ignore
          setThumbWidth(e.nativeEvent.layout.width)),
          ref: forwardedRef
        })
      );
    }),
    SwitchComponent = Frame.styleable(function (propsIn, forwardedRef) {
      const styledContext = React.useContext(SwitchContext),
        props = useProps(propsIn, {
          noNormalize: !0,
          noExpand: !0,
          resolveValues: "none",
          forComponent: Frame
        }),
        {
          labeledBy: ariaLabelledby,
          name,
          checked: checkedProp,
          defaultChecked,
          required,
          disabled,
          value = "on",
          onCheckedChange,
          size = styledContext.size ?? "$true",
          unstyled = styledContext.unstyled ?? !1,
          native: nativeProp,
          nativeProps,
          children,
          ...switchProps
        } = props,
        native = Array.isArray(nativeProp) ? nativeProp : [nativeProp],
        shouldRenderMobileNative = !isWeb && nativeProp === !0 || !isWeb && native.includes("mobile") || native.includes("android") && Platform.OS === "android" || native.includes("ios") && Platform.OS === "ios",
        [button, setButton] = React.useState(null),
        composedRefs = useComposedRefs(forwardedRef, setButton),
        labelId = useLabelContext(button),
        labelledBy = ariaLabelledby || labelId,
        hasConsumerStoppedPropagationRef = React.useRef(!1),
        isFormControl = isWeb ? button ? !!button.closest("form") : !0 : !1,
        [frameWidth, setFrameWidth] = React.useState(0),
        [checked = !1, setChecked] = useControllableState({
          prop: checkedProp,
          defaultProp: defaultChecked || !1,
          onChange: onCheckedChange,
          transition: !0
        });
      if (shouldRenderMobileNative) return /* @__PURE__ */jsx(NativeSwitch, {
        value: checkedProp,
        onValueChange: onCheckedChange,
        ...nativeProps
      });
      isWeb || React.useEffect(() => {
        if (props.id) return registerFocusable(props.id, {
          focus: () => {
            setChecked(x => !x);
          }
        });
      }, [props.id, setChecked]);
      const isInsideButton = React.useContext(ButtonNestingContext);
      return /* @__PURE__ */jsxs(Fragment, {
        children: [/* @__PURE__ */jsx(ButtonNestingContext.Provider, {
          value: !0,
          children: /* @__PURE__ */jsx(Frame, {
            tag: isInsideButton ? "span" : "button",
            unstyled,
            size,
            checked,
            disabled,
            frameWidth,
            themeShallow: !0,
            ...(!disableActiveTheme && {
              theme: checked ? "active" : null,
              themeShallow: !0
            }),
            role: "switch",
            "aria-checked": checked,
            "aria-labelledby": labelledBy,
            "aria-required": required,
            "data-state": getState(checked),
            "data-disabled": disabled ? "" : void 0,
            tabIndex: disabled ? void 0 : 0,
            value,
            ...switchProps,
            ref: composedRefs,
            onPress: composeEventHandlers(props.onPress, event => {
              setChecked(prevChecked => !prevChecked), isWeb && isFormControl && (hasConsumerStoppedPropagationRef.current = event.isPropagationStopped(), hasConsumerStoppedPropagationRef.current || event.stopPropagation());
            }),
            children: /* @__PURE__ */jsx(YStack, {
              alignSelf: "stretch",
              flex: 1,
              onLayout: e => {
                setFrameWidth(e.nativeEvent.layout.width);
              },
              children: typeof children == "function" ? children(checked) : children
            })
          })
        }), isWeb && isFormControl && /* @__PURE__ */jsx(BubbleInput, {
          control: button,
          bubbles: !hasConsumerStoppedPropagationRef.current,
          name,
          value,
          checked,
          required,
          disabled,
          style: {
            transform: "translateX(-100%)"
          }
        })]
      });
    }, {
      disableTheme: !0
    }),
    BubbleInput = props => {
      const {
          control,
          checked,
          bubbles = !0,
          ...inputProps
        } = props,
        ref = React.useRef(null),
        prevChecked = usePrevious(checked);
      return React.useEffect(() => {
        const input = ref.current,
          inputProto = window.HTMLInputElement.prototype,
          setChecked = Object.getOwnPropertyDescriptor(inputProto, "checked").set;
        if (prevChecked !== checked && setChecked) {
          const event = new Event("click", {
            bubbles
          });
          setChecked.call(input, checked), input.dispatchEvent(event);
        }
      }, [prevChecked, checked, bubbles]),
      // @ts-ignore
      /* @__PURE__ */
      jsx("input", {
        type: "checkbox",
        "aria-hidden": !0,
        defaultChecked: checked,
        ...inputProps,
        tabIndex: -1,
        ref,
        style: {
          ...props.style,
          // ...controlSize,
          position: "absolute",
          pointerEvents: "none",
          opacity: 0,
          margin: 0
        }
      });
    };
  function getState(checked) {
    return checked ? "checked" : "unchecked";
  }
  return withStaticProperties(SwitchComponent, {
    Thumb: SwitchThumbComponent
  });
}
export { createSwitch };