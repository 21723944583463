import { useTheme } from "./hooks/useTheme.mjs";
import { jsx } from "react/jsx-runtime";
const internalWithTheme = (Component, styleProvider) => props => {
  const {
      expressions,
      ...rest
    } = props,
    theme = useTheme();
  return /* @__PURE__ */jsx(Component, {
    style: styleProvider(theme, expressions),
    ...rest
  });
};
export { internalWithTheme };