import { useContext, useEffect, useId } from "react";
import { PresenceContext } from "./PresenceContext.mjs";
function usePresence() {
  const context = useContext(PresenceContext);
  if (!context) return [!0, null, context];
  const {
      isPresent: isPresent2,
      onExitComplete,
      register
    } = context,
    id = useId() || "";
  return useEffect(() => register(id), []), !isPresent2 && onExitComplete ? [!1, () => onExitComplete?.(id), context] : [!0, void 0, context];
}
function useIsPresent() {
  return isPresent(useContext(PresenceContext));
}
function isPresent(context) {
  return context === null ? !0 : context.isPresent;
}
export { isPresent, useIsPresent, usePresence };