import { useComposedRefs } from "@tamagui/compose-refs";
import { isWeb, useIsomorphicLayoutEffect } from "@tamagui/constants";
import { Stack, View as TamaguiView, createStyledContext, getVariableValue, styled, useDidFinishSSR, useProps } from "@tamagui/core";
import { arrow, autoUpdate, flip, offset as offsetFn, shift, useFloating } from "@tamagui/floating";
import { getSpace } from "@tamagui/get-token";
import { ThemeableStack, YStack } from "@tamagui/stacks";
import * as React from "react";
import { Keyboard, useWindowDimensions } from "react-native-web";
import { jsx } from "react/jsx-runtime";
const PopperContext = createStyledContext({}),
  {
    useStyledContext: usePopperContext,
    Provider: PopperProvider
  } = PopperContext;
function Popper(props) {
  const {
      children,
      size,
      strategy = "absolute",
      placement = "bottom",
      stayInFrame,
      allowFlip,
      offset,
      __scopePopper
    } = props,
    isMounted = useDidFinishSSR(),
    [arrowEl, setArrow] = React.useState(null),
    [arrowSize, setArrowSize] = React.useState(0),
    offsetOptions = offset ?? arrowSize,
    floating = useFloating({
      strategy,
      placement,
      sameScrollView: !1,
      // this only takes effect on native
      middleware: [stayInFrame ? shift(typeof stayInFrame == "boolean" ? {} : stayInFrame) : null, allowFlip ? flip(typeof allowFlip == "boolean" ? {} : allowFlip) : null, arrowEl ? arrow({
        element: arrowEl
      }) : null, typeof offsetOptions < "u" ? offsetFn(offsetOptions) : null].filter(Boolean)
    }),
    {
      refs,
      middlewareData,
      // @ts-expect-error this comes from Tooltip for example
      open
    } = floating;
  if (isWeb) useIsomorphicLayoutEffect(() => {
    if (open && refs.reference.current && refs.floating.current) return autoUpdate(refs.reference.current, refs.floating.current, floating.update);
  }, [open, floating.update, refs.floating, refs.reference]);else {
    const dimensions = useWindowDimensions(),
      [keyboardOpen, setKeyboardOpen] = React.useState(!1);
    React.useEffect(() => {
      const showSubscription = Keyboard.addListener("keyboardDidShow", () => {
          setKeyboardOpen(!0);
        }),
        hideSubscription = Keyboard.addListener("keyboardDidHide", () => {
          setKeyboardOpen(!1);
        });
      return () => {
        showSubscription.remove(), hideSubscription.remove();
      };
    }, []), useIsomorphicLayoutEffect(() => {
      floating.update();
    }, [dimensions, keyboardOpen]);
  }
  const popperContext = {
    size,
    arrowRef: setArrow,
    arrowStyle: middlewareData.arrow,
    onArrowSize: setArrowSize,
    isMounted,
    scope: __scopePopper,
    ...floating
  };
  return /* @__PURE__ */jsx(PopperProvider, {
    ...popperContext,
    children
  });
}
const PopperAnchor = YStack.extractable(React.forwardRef(function (props, forwardedRef) {
    const {
        virtualRef,
        __scopePopper,
        ...anchorProps
      } = props,
      {
        getReferenceProps,
        refs
      } = usePopperContext(__scopePopper),
      ref = React.useRef(null),
      composedRefs = useComposedRefs(forwardedRef, ref, virtualRef ?? refs.setReference);
    if (virtualRef) return null;
    const stackProps = {
      ref: composedRefs,
      ...anchorProps
    };
    return /* @__PURE__ */jsx(TamaguiView, {
      ...(getReferenceProps ? getReferenceProps(stackProps) : stackProps)
    });
  })),
  PopperContentFrame = styled(ThemeableStack, {
    name: "PopperContent",
    variants: {
      unstyled: {
        false: {
          size: "$true",
          backgroundColor: "$background",
          alignItems: "center",
          radiused: !0
        }
      },
      size: {
        "...size": (val, {
          tokens
        }) => ({
          padding: tokens.space[val],
          borderRadius: tokens.radius[val]
        })
      }
    },
    defaultVariants: {
      unstyled: process.env.TAMAGUI_HEADLESS === "1"
    }
  }),
  PopperContent = React.forwardRef(function (props, forwardedRef) {
    const {
        __scopePopper,
        enableAnimationForPositionChange,
        ...rest
      } = props,
      {
        strategy,
        placement,
        refs,
        x,
        y,
        getFloatingProps,
        size,
        isMounted,
        update
      } = usePopperContext(__scopePopper),
      contentRefs = useComposedRefs(refs.setFloating, forwardedRef),
      contents = React.useMemo(() => /* @__PURE__ */jsx(PopperContentFrame, {
        "data-placement": placement,
        "data-strategy": strategy,
        contain: "layout",
        size,
        ...rest
      }, "popper-content-frame"), [placement, strategy, props]),
      [hasInitialPosition, setHasInitialPosition] = React.useState(!0);
    if (React.useEffect(() => {
      (x || y) && setHasInitialPosition(!1);
    }, [x, y]), useIsomorphicLayoutEffect(() => {
      isMounted && update();
    }, [isMounted]), !isMounted) return null;
    const frameProps = {
      ref: contentRefs,
      x: x || 0,
      y: y || 0,
      top: 0,
      left: 0,
      position: strategy,
      ...(enableAnimationForPositionChange && {
        // apply animation but disable it on initial render to avoid animating from 0 to the first position
        animation: rest.animation,
        animateOnly: ["none"]
      }),
      ...(enableAnimationForPositionChange && !hasInitialPosition && {
        animation: rest.animation,
        animateOnly: rest.animateOnly
      })
    };
    return /* @__PURE__ */jsx(Stack, {
      ...(getFloatingProps ? getFloatingProps(frameProps) : frameProps),
      children: contents
    });
  }),
  PopperArrowFrame = styled(YStack, {
    name: "PopperArrow",
    variants: {
      unstyled: {
        false: {
          borderColor: "$borderColor",
          backgroundColor: "$background",
          position: "relative"
        }
      }
    },
    defaultVariants: {
      unstyled: process.env.TAMAGUI_HEADLESS === "1"
    }
  }),
  PopperArrowOuterFrame = styled(YStack, {
    name: "PopperArrowOuter",
    variants: {
      unstyled: {
        false: {
          position: "absolute",
          zIndex: 1e6,
          pointerEvents: "none",
          overflow: "hidden",
          alignItems: "center",
          justifyContent: "center"
        }
      }
    },
    defaultVariants: {
      unstyled: process.env.TAMAGUI_HEADLESS === "1"
    }
  }),
  opposites = {
    top: "bottom",
    right: "left",
    bottom: "top",
    left: "right"
  },
  PopperArrow = PopperArrowFrame.styleable(function (propsIn, forwardedRef) {
    const {
        __scopePopper,
        ...rest
      } = propsIn,
      props = useProps(rest),
      {
        offset,
        size: sizeProp,
        borderWidth = 0,
        ...arrowProps
      } = props,
      context = usePopperContext(__scopePopper),
      sizeVal = sizeProp ?? context.size,
      sizeValResolved = getVariableValue(getSpace(sizeVal, {
        shift: -2,
        bounds: [2]
      })),
      size = Math.max(0, +sizeValResolved),
      {
        placement
      } = context,
      refs = useComposedRefs(context.arrowRef, forwardedRef),
      x = context.arrowStyle?.x || 0,
      y = context.arrowStyle?.y || 0,
      primaryPlacement = placement ? placement.split("-")[0] : "top",
      arrowStyle = {
        x,
        y,
        width: size,
        height: size
      },
      innerArrowStyle = {},
      isVertical = primaryPlacement === "bottom" || primaryPlacement === "top";
    if (primaryPlacement) {
      arrowStyle[isVertical ? "width" : "height"] = size * 2;
      const oppSide = opposites[primaryPlacement];
      oppSide && (arrowStyle[oppSide] = -size, innerArrowStyle[oppSide] = size / 2), oppSide === "bottom" && (arrowStyle[oppSide] += 1), (oppSide === "top" || oppSide === "bottom") && (arrowStyle.left = 0), (oppSide === "left" || oppSide === "right") && (arrowStyle.top = 0), useIsomorphicLayoutEffect(() => {
        context.onArrowSize?.(size);
      }, [size, context.onArrowSize]);
    }
    return /* @__PURE__ */jsx(PopperArrowOuterFrame, {
      ref: refs,
      ...arrowStyle,
      children: /* @__PURE__ */jsx(PopperArrowFrame, {
        width: size,
        height: size,
        ...arrowProps,
        ...innerArrowStyle,
        rotate: "45deg",
        ...(primaryPlacement === "bottom" && {
          borderLeftWidth: borderWidth,
          borderTopWidth: borderWidth
        }),
        ...(primaryPlacement === "top" && {
          borderBottomWidth: borderWidth,
          borderRightWidth: borderWidth
        }),
        ...(primaryPlacement === "right" && {
          borderLeftWidth: borderWidth,
          borderBottomWidth: borderWidth
        }),
        ...(primaryPlacement === "left" && {
          borderTopWidth: borderWidth,
          borderRightWidth: borderWidth
        })
      })
    });
  });
export { Popper, PopperAnchor, PopperArrow, PopperContent, PopperContentFrame, PopperContext, PopperProvider, usePopperContext };