import { applyMask, createMask, createThemeWithPalettes, objectEntries, objectFromEntries } from "@tamagui/create-theme";
class ThemeBuilder {
  constructor(state) {
    this.state = state;
  }
  addPalettes(palettes) {
    return this.state.palettes = {
      // as {} prevents generic string key merge messing up types
      ...this.state.palettes,
      ...palettes
    }, this;
  }
  addTemplates(templates) {
    return this.state.templates = {
      // as {} prevents generic string key merge messing up types
      ...this.state.templates,
      ...templates
    }, this;
  }
  addMasks(masks) {
    return this.state.masks = {
      // as {} prevents generic string key merge messing up types
      ...this.state.masks,
      ...objectFromEntries(objectEntries(masks).map(([key, val]) => [key, createMask(val)]))
    }, this;
  }
  // for dev mode only really
  _addedThemes = [];
  addThemes(themes) {
    return this._addedThemes.push({
      type: "themes",
      args: [themes]
    }), this.state.themes = {
      // as {} prevents generic string key merge messing up types
      ...this.state.themes,
      ...themes
    }, this;
  }
  addChildThemes(childThemeDefinition, options) {
    const currentThemes = this.state.themes;
    if (!currentThemes) throw new Error("No themes defined yet, use addThemes first to set your base themes");
    this._addedThemes.push({
      type: "childThemes",
      args: [childThemeDefinition, options]
    });
    const currentThemeNames = Object.keys(currentThemes),
      incomingThemeNames = Object.keys(childThemeDefinition),
      namesWithDefinitions = currentThemeNames.flatMap(prefix => {
        const avoidNestingWithin = options?.avoidNestingWithin;
        return avoidNestingWithin && avoidNestingWithin.some(avoidName => prefix.startsWith(avoidName) || prefix.endsWith(avoidName)) ? [] : incomingThemeNames.map(subName => {
          const fullName = `${prefix}_${subName}`,
            definition = childThemeDefinition[subName];
          return "avoidNestingWithin" in definition && definition.avoidNestingWithin.some(name => prefix.startsWith(name) || prefix.endsWith(name)) ? null : [fullName, definition];
        }).filter(Boolean);
      }),
      childThemes = Object.fromEntries(namesWithDefinitions),
      next = {
        // as {} prevents generic string key merge messing up types
        ...this.state.themes,
        ...childThemes
      };
    return this.state.themes = next, this;
  }
  build() {
    if (!this.state.themes) return {};
    const out = {},
      maskedThemes = [];
    for (const themeName in this.state.themes) {
      const nameParts = themeName.split("_"),
        parentName = nameParts.slice(0, nameParts.length - 1).join("_"),
        definitions = this.state.themes[themeName],
        themeDefinition = Array.isArray(definitions) ? (() => {
          const found = definitions.find(
          // endWith match stronger than startsWith
          d => parentName.endsWith(d.parent) || parentName.startsWith(d.parent));
          return found || null;
        })() : definitions;
      if (themeDefinition) if ("theme" in themeDefinition) out[themeName] = themeDefinition.theme;else if ("mask" in themeDefinition) maskedThemes.push({
        parentName,
        themeName,
        mask: themeDefinition
      });else {
        let {
          palette: paletteName,
          template: templateName,
          ...options
        } = themeDefinition;
        if (!this.state.palettes) throw new Error(`No palettes defined for theme with palette expected: ${themeName}`);
        let palette = this.state.palettes[paletteName];
        if (palette || (paletteName = `${parentName}_${paletteName}`, palette = this.state.palettes[paletteName]), !palette) throw new Error(`No palette for theme ${themeName}: ${paletteName} (${Object.keys(this.state.palettes).join(", ")})`);
        const template = this.state.templates?.[templateName];
        if (!template) throw new Error(`No template for theme ${themeName}: ${templateName}`);
        out[themeName] = createThemeWithPalettes(this.state.palettes, paletteName, template, options, themeName, !0);
      }
    }
    for (const {
      mask,
      themeName,
      parentName
    } of maskedThemes) {
      const parent = out[parentName];
      if (!parent) continue;
      const {
        mask: maskName,
        ...options
      } = mask;
      let maskFunction = this.state.masks?.[maskName];
      if (!maskFunction) throw new Error(`No mask ${maskFunction}`);
      const parentTheme = this.state.themes[parentName];
      if (parentTheme && "childOptions" in parentTheme) {
        const {
          mask: mask2,
          ...childOpts
        } = parentTheme.childOptions;
        mask2 && (maskFunction = this.state.masks?.[mask2]), Object.assign(options, childOpts);
      }
      out[themeName] = applyMask(parent, maskFunction, options, parentName, themeName);
    }
    return out;
  }
}
function createThemeBuilder() {
  return new ThemeBuilder({});
}
export { ThemeBuilder, createThemeBuilder };