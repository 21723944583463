import { getFontSized } from "@tamagui/get-font-sized";
import { Text, styled } from "@tamagui/web";
const variants = {
  unstyled: {
    false: {
      size: "$true",
      color: "$color"
    }
  },
  size: getFontSized
};
variants.fontFamily = {
  "...": (_, extras) => {
    const size = extras.props.size || "$true";
    return getFontSized(size, extras);
  }
};
const SizableText = styled(Text, {
  name: "SizableText",
  fontFamily: "$body",
  variants,
  defaultVariants: {
    unstyled: process.env.TAMAGUI_HEADLESS === "1"
  }
});
export { SizableText };