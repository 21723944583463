function concatClassName(_cn) {
  const args = arguments,
    usedPrefixes = [];
  let final = "";
  const len = args.length;
  let propObjects = null;
  for (let x = len; x >= 0; x--) {
    const cns = args[x];
    if (!cns) continue;
    if (!Array.isArray(cns) && typeof cns != "string") {
      propObjects = propObjects || [], propObjects.push(cns);
      continue;
    }
    const names = Array.isArray(cns) ? cns : cns.split(" "),
      numNames = names.length;
    for (let i = numNames - 1; i >= 0; i--) {
      const name = names[i];
      if (!name || name === " ") continue;
      if (name[0] !== "_") {
        final = name + " " + final;
        continue;
      }
      const splitIndex = name.indexOf("-");
      if (splitIndex < 1) {
        final = name + " " + final;
        continue;
      }
      const isMediaQuery = name[splitIndex + 1] === "_",
        styleKey = name.slice(1, name.lastIndexOf("-")),
        mediaKey = isMediaQuery ? name.slice(splitIndex + 2, splitIndex + 7) : null,
        uid = mediaKey ? styleKey + mediaKey : styleKey;
      if (usedPrefixes.indexOf(uid) > -1) continue;
      usedPrefixes.push(uid);
      const propName = styleKey;
      propName && propObjects && propObjects.some(po => {
        if (mediaKey) {
          const propKey = pseudoInvert[mediaKey];
          return po && po[propKey] && propName in po[propKey] && po[propKey] !== null;
        }
        return po && propName in po && po[propName] !== null;
      }) || (final = name + " " + final);
    }
  }
  return final;
}
const pseudoInvert = {
  hover: "hoverStyle",
  focus: "focusStyle",
  press: "pressStyle"
};
export { concatClassName };