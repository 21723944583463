import { getVariableValue } from "../createVariable.mjs";
function getVariantExtras(styleState) {
  const {
    curProps,
    conf,
    context,
    theme
  } = styleState;
  let fonts = conf.fontsParsed;
  context?.language && (fonts = getFontsForLanguage(conf.fontsParsed, context.language));
  const fontFamily = getVariableValue(styleState.fontFamily || styleState.curProps.fontFamily || styleState.conf.defaultFont);
  return {
    fonts,
    tokens: conf.tokensParsed,
    theme,
    fontFamily,
    font: fonts[fontFamily] || fonts[styleState.conf.defaultFont],
    // TODO do this in splitstlye
    // we avoid passing in default props for media queries because that would confuse things like SizableText.size:
    props: new Proxy(curProps, {
      // handles shorthands
      get(target, key) {
        const shorthand = conf.inverseShorthands[key];
        if (shorthand && Reflect.has(target, shorthand)) return Reflect.get(target, shorthand);
        if (Reflect.has(target, key)) return Reflect.get(target, key);
      }
    })
  };
}
const fontLanguageCache = /* @__PURE__ */new WeakMap();
function getFontsForLanguage(fonts, language) {
  if (fontLanguageCache.has(language)) return fontLanguageCache.get(language);
  const next = {
    ...fonts,
    ...Object.fromEntries(Object.entries(language).map(([name, lang]) => {
      if (lang === "default") return [];
      const langKey = `$${name}_${lang}`;
      return [`$${name}`, fonts[langKey]];
    }))
  };
  return fontLanguageCache.set(language, next), next;
}
export { getFontsForLanguage, getVariantExtras };