import { simpleHash } from "@tamagui/helpers";
import { getConfig } from "../config.mjs";
import { defaultOffset } from "./defaultOffset.mjs";
import { normalizeValueWithProperty } from "./normalizeValueWithProperty.mjs";
import { pseudoDescriptors, pseudoDescriptorsBase } from "./pseudoDescriptors.mjs";
import { normalizeColor } from "./normalizeColor.mjs";
function getStylesAtomic(stylesIn, debug) {
  const res = [];
  for (const pseudoName in pseudoDescriptorsBase) {
    const pseudoStyle = stylesIn[pseudoName];
    pseudoStyle && res.push(...generateAtomicStyles(pseudoStyle, pseudoDescriptorsBase[pseudoName]));
  }
  return res.push(...generateAtomicStyles(stylesIn)), process.env.NODE_ENV === "development" && debug === "verbose" && console.info(" \u{1FAAE} getStylesAtomic", {
    stylesIn,
    res
  }), res;
}
let conf;
function transformsToString(transforms) {
  return transforms.map(
  // { scale: 2 } => 'scale(2)'
  // { translateX: 20 } => 'translateX(20px)'
  // { matrix: [1,2,3,4,5,6] } => 'matrix(1,2,3,4,5,6)'
  transform => {
    const type = Object.keys(transform)[0],
      value = transform[type];
    return type === "matrix" || type === "matrix3d" ? `${type}(${value.join(",")})` : `${type}(${normalizeValueWithProperty(value, type)})`;
  }).join(" ");
}
const generateAtomicStyles = (style, pseudo) => {
  if (!style) return [];
  conf = conf || getConfig(), styleToCSS(style);
  const out = [];
  for (const key in style) {
    if (key in pseudoDescriptors) continue;
    let val = style[key];
    if (val == null) continue;
    key === "transform" && Array.isArray(style.transform) && (val = transformsToString(val));
    const value = normalizeValueWithProperty(val, key),
      hash = simpleHash(`${value}`),
      pseudoPrefix = pseudo ? `0${pseudo.name}-` : "",
      identifier = `_${conf.inverseShorthands[key] || key}-${pseudoPrefix}${hash}`,
      rules = createAtomicRules(identifier, key, value, pseudo),
      styleObject = {
        property: key,
        pseudo: pseudo?.name,
        identifier,
        rules,
        value
      };
    out.push(styleObject);
  }
  return out;
};
function styleToCSS(style) {
  const {
    shadowOffset,
    shadowRadius,
    shadowColor,
    shadowOpacity
  } = style;
  if (shadowRadius || shadowColor) {
    const offset = shadowOffset || defaultOffset,
      width = normalizeValueWithProperty(offset.width),
      height = normalizeValueWithProperty(offset.height),
      radius = normalizeValueWithProperty(shadowRadius),
      color = normalizeColor(shadowColor, shadowOpacity),
      shadow = `${width} ${height} ${radius} ${color}`;
    style.boxShadow = style.boxShadow ? `${style.boxShadow}, ${shadow}` : shadow, delete style.shadowOffset, delete style.shadowRadius, delete style.shadowColor, delete style.shadowOpacity;
  }
  const {
    textShadowColor,
    textShadowOffset,
    textShadowRadius
  } = style;
  if (textShadowColor || textShadowOffset || textShadowRadius) {
    const {
        height,
        width
      } = textShadowOffset || defaultOffset,
      radius = textShadowRadius || 0,
      color = normalizeValueWithProperty(textShadowColor, "textShadowColor");
    if (color && (height !== 0 || width !== 0 || radius !== 0)) {
      const blurRadius = normalizeValueWithProperty(radius),
        offsetX = normalizeValueWithProperty(width),
        offsetY = normalizeValueWithProperty(height);
      style.textShadow = `${offsetX} ${offsetY} ${blurRadius} ${color}`;
    }
    delete style.textShadowColor, delete style.textShadowOffset, delete style.textShadowRadius;
  }
}
function createDeclarationBlock(style, important = !1) {
  let next = "";
  for (const [key, value] of style) next += `${hyphenateStyleName(key)}:${value}${important ? " !important" : ""};`;
  return `{${next}}`;
}
const hcache = {},
  toHyphenLower = match => `-${match.toLowerCase()}`,
  hyphenateStyleName = key => {
    if (key in hcache) return hcache[key];
    const val = key.replace(/[A-Z]/g, toHyphenLower);
    return hcache[key] = val, val;
  },
  selectorPriority = (() => {
    const res = {};
    for (const key in pseudoDescriptors) {
      const pseudo = pseudoDescriptors[key];
      res[pseudo.name] = `${[...Array(pseudo.priority)].map(() => ":root").join("")} `;
    }
    return res;
  })();
function createAtomicRules(identifier, property, value, pseudo) {
  const selector = pseudo ? pseudo?.selector ? `${pseudo?.selector} .${identifier}` : `${selectorPriority[pseudo.name]} .${identifier}:${pseudo.name}` : `:root .${identifier}`,
    important = !!pseudo;
  let rules = [];
  switch (property) {
    case "placeholderTextColor":
      {
        const block = createDeclarationBlock([["color", value], ["opacity", 1]], important);
        rules.push(`${selector}::placeholder${block}`);
        break;
      }
    case "backgroundClip":
    case "userSelect":
      {
        const webkitProperty = `Webkit${`${property[0].toUpperCase()}${property.slice(1)}`}`,
          block = createDeclarationBlock([[property, value], [webkitProperty, value]], important);
        rules.push(`${selector}${block}`);
        break;
      }
    case "pointerEvents":
      {
        let finalValue = value;
        value === "auto" || value === "box-only" ? (finalValue = "auto", value === "box-only" && rules.push(`${selector}>*${boxOnly}`)) : (value === "none" || value === "box-none") && (finalValue = "none", value === "box-none" && rules.push(`${selector}>*${boxNone}`));
        const block = createDeclarationBlock([["pointerEvents", finalValue]], !0);
        rules.push(`${selector}${block}`);
        break;
      }
    default:
      {
        const block = createDeclarationBlock([[property, value]], important);
        rules.push(`${selector}${block}`);
        break;
      }
  }
  return pseudo?.name === "hover" && (rules = rules.map(r => `@media not all and (hover: none) { ${r} }`)), rules;
}
const boxNone = createDeclarationBlock([["pointerEvents", "auto"]], !0),
  boxOnly = createDeclarationBlock([["pointerEvents", "none"]], !0);
export { generateAtomicStyles, getStylesAtomic, styleToCSS, transformsToString };